import * as React from "react";
import { styled } from "@mui/system";
import Tabs from "@mui/material/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import TabPanel from "@mui/lab/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import Tab from "@mui/material/Tab";
import { tabClasses } from "@mui/material/Tab";
import StatutoryTable from "./StatutoryTable";
import NonStatutoryTable from "./NonStatutoryTable ";
import NonJobRelatedTable from "./NonJobRelatedTable";
import DashboardLayout from "customs/LayoutContainers/DashboardLayout";
import DashboardNavbar from "customs/Navbars/DashboardNavbar";

const green = {
  50: "#e7fbfe",
  100: "#C2E0FF",
  200: "#86edf9",
  300: "#66B2FF",
  400: "#3de2f5",
  500: "#032c30",
  600: "#054248",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tabss = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[600]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: 2px solid ${green[200]};
    outline-offset: 2px;
  }

  &.${tabClasses.selected} {
    background-color: ${green[50]};
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanels = styled(TabPanel)`
  width: 100s%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(BaseTabsList)`
  min-width: 320px;
  background-color: ${green[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function Declined() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs defaultValue={0}>
        <TabsList
          sx={{
            width: "100%",
            marginTop: 3,
          }}
        >
          <Tabss>Statutory Job Related Request</Tabss>
          <Tabss>Non-Statutory Job Related Request</Tabss>
          <Tabss>Non-Job Related Request</Tabss>
        </TabsList>
        <TabPanels value={0}>
          <StatutoryTable />
        </TabPanels>
        <TabPanels value={1}>
          <NonStatutoryTable />
        </TabPanels>
        <TabPanels value={2}>
          <NonJobRelatedTable />
        </TabPanels>
      </Tabs>
    </DashboardLayout>
  );
}
